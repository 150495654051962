import {isFuture, isPast} from 'date-fns'
import React from 'react'
import ProductPreview from './product-preview'
import styles from './product-grid.module.css'

function ProductGrid (props) {
  if (props.nodes.length < 1) {
    return <div>ERROR: Keine Elemente??</div>
  }
  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map(node => {
            if (
              !isFuture(node.publishedAt) &&
              (!node.gueltigAb || !isFuture(node.gueltigAb)) &&
              (!node.gueltigBis || !isPast(node.gueltigBis))
            ) {
              return (
                <li key={node.id}>
                  <ProductPreview {...node} isInList />
                </li>
              )
            }
          })}
      </ul>
    </div>
  )
}

ProductGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ProductGrid
