import {format} from 'date-fns'
import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, cn, getProductUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './product-preview.module.css'
import {responsiveTitle3} from './typography.module.css'
import Container from './container'

function ProductPreview (props) {
  const {gueltigAb, gueltigBis, isAktion, price, reducedPrice} = props
  return (
    <Container>
      <Link
        className={props.isInList ? styles.inList : styles.inGrid}
        to={getProductUrl(props.publishedAt, props.slug.current)}
      >
        <div className={styles.leadMediaThumb}>
          {props.mainImage && props.mainImage.asset && (
            <img
              src={imageUrlFor(buildImageObj(props.mainImage))
                .width(400)
                .height(Math.floor((9 / 16) * 400))
                .auto('format')
                .url()}
              alt={props.mainImage.alt}
            />
          )}
        </div>
        <div className={styles.text}>
          <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
          {isAktion ? (
            <>
              <span className={styles.reducedPrice}>CHF {reducedPrice}</span>{' '}
              <span className={styles.aktionPrice}>CHF {price}</span>
              <br />
              <span className={styles.gueltigAb}>{format(gueltigAb, 'DD.MM.YYYY')}</span> -{' '}
              <span className={styles.gueltigBis}>{format(gueltigBis, 'DD.MM.YYYY')}</span>
            </>
          ) : (
            <>
              <span className={styles.price}>CHF {price}</span>
              <br />
              Neu ab dem{' '}
              <span className={styles.gueltigBis}>{format(gueltigBis, 'DD.MM.YYYY')}</span>
            </>
          )}
        </div>
      </Link>
    </Container>
  )
}

export default ProductPreview
