import {differenceInDays} from 'date-fns'
import {Link} from 'gatsby'
import React from 'react'
import BlogPostPreview from './blog-post-preview'
import styles from './blog-post-preview-list.module.css'

function BlogPostPreviewGrid (props) {
  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map(node => {
            const today = new Date()
            if (
              differenceInDays(new Date(node.publishedAt), today) <= 0 &&
              (!node.unpublishAt || differenceInDays(new Date(node.unpublishAt), today) >= 0)
            ) {
              return (
                <li key={node.id}>
                  <BlogPostPreview {...node} isInList />
                </li>
              )
            }
          })}
      </ul>
      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )}
    </div>
  )
}

BlogPostPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BlogPostPreviewGrid
